import axios, { type AxiosRequestConfig, type AxiosResponse } from "axios";

export const useApi = () => {
  const auth = useAuthStore();
  const { public: $env } = useRuntimeConfig();

  const bearerToken = computed(() => auth.authResponseParams?.oauth_token);

  // const $fetch = useFetch;

  // const fetch = <T>(
  //   path: string,
  //   options: Parameters<typeof $fetch<T>>[1] = {},
  // ) => {
  //   return $fetch<T>(path, {
  //     baseURL: $env.STREAMLABS_API_BASE_PATH,
  //     ...options,
  //     headers: {
  //       ...(bearerToken.value && {
  //         Authorization: `Bearer ${bearerToken.value}`,
  //       }),
  //       ...(options.headers ? unref(options.headers) : {}),
  //     },
  //     server: false,
  //   });
  // };

  const axiosInstance = axios.create({
    baseURL: $env.STREAMLABS_API_BASE_PATH,
    // withCredentials: true,
  });

  axiosInstance.interceptors.request.use((config) => {
    if (!config?.headers?.Authorization && bearerToken.value) {
      console.log("interceptors.request.use", {
        bearerToken: bearerToken.value,
      });
      config["headers"]["Authorization"] = `Bearer ${bearerToken.value}`;
    }

    return config;
  });

  // const axiosFetch = async <T>(
  //   path: string,
  //   options: AxiosRequestConfig = {},
  // ) => {
  //   return axiosInstance.request<T>({
  //     url: path,
  //     method: options.method || "get", // Default to GET if no method is provided
  //     ...options,
  //     headers: {
  //       ...(bearerToken.value && {
  //         Authorization: `Bearer ${bearerToken.value}`,
  //       }),
  //       ...(options.headers || {}),
  //     },
  //   });
  //   // .then((res) => {
  //   //   return {
  //   //     data: {
  //   //       value: res.data,
  //   //     },
  //   //   };
  //   // });
  // };

  return axiosInstance;

  // return {
  //   fetch: axiosFetch,
  // };
};
